import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
// import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
// import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

import Header from "../components/headers/headerLight.js";


const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    // <AnimationRevealPage>
    

      <StyledDiv className="App">
       <Header roundedHeaderButton={true}  />



      {/* Sign Up Section */}
      <Hero roundedHeaderButton={true} />

      <AnimationRevealPage>
  
      {/* <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      /> */}
      <FeatureWithSteps
        subheading={<Subheading>GET STARTED</Subheading>}
        heading={
          <>
            It's easy to <HighlightedText>get started</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />

      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We help you stay aware of the best rates 
          </>
        }
      />

      {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      /> */}
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            We are free!
          </>
        }
        // plans={[
        //   {
        //     name: "Personal",
        //     price: "$17.99",
        //     duration: "Monthly",
        //     mainFeature: "For Individuals",
        //     features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
        //   },
        //   {
        //     name: "Business",
        //     price: "$37.99",
        //     duration: "Monthly",
        //     mainFeature: "For Small Businesses",
        //     features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
        //     featured: true
        //   },
        //   {
        //     name: "Enterprise",
        //     price: "$57.99",
        //     duration: "Monthly",
        //     mainFeature: "For Large Companies",
        //     features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
        //   }
        // ]}
      />
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Any questions?
          </>
        }
        faqs={[
          {
            question: "Do you have access to my Octopus account?",
            answer:
              "Nope, we only know the name of the electricity tariff you are on."
          },
          {
            question: "How do I find out the name of my electricity tariff?",
            answer:
              "You'll find this from the 'My Account' section of your Octopus Energy account."
          },
          {
            question: "Can you see my electricity usage?",
            answer:
              "We can't, but we may consider adding this feature in the future (with your permission of course). In the meantime, you can find your usage from the 'Usage' section of your Octopus Energy account."
          },
          {
            question: "What Octopus tariffs do you have available?",
            answer:
              "We have Octopus Agile and Tracker import rates and Octopus Agile export (outgoing) rates available. These are listed in the sign-up section, and include import rates such as Octopus Agile April 2024 v1 (AGILE-24-04-03) and Octopus Agile December 2023 v1 (AGILE-23-12-06), as well as export rates such as Agile Outgoing Octopus February 2023 v1 (AGILE-OUTGOING-BB-23-02-28)."
          },
          {
            question: "How can I sign up to Octopus Agile or Octopus Tracker?",
            answer:
              "You'll need to sign up to Octopus Energy first, plus have a smart meter, and then you can switch to Octopus Agile or Octopus Tracker. If you use our referral link at the bottom of this page, you'll get £50 credit added to your account."
          },
              {
            question: "Can I unsubscribe?",
            answer:
              "Of course, any time. We'll be sorry to see you go, but to do so, follow the 'Unsubscribe' link at the bottom of this page."
          },
        ]}
      />
      {/* <GetStarted/> */}
    </AnimationRevealPage>
      <Footer />
    {/* </AnimationRevealPage> */}
    </StyledDiv>
  );
}

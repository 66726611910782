// Page to display blog posts

import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/headers/headerLight.js";
import Footer from "../components/footers/FiveColumnWithBackground.js";

// Import firebase items
// Import firebase helper file
// import { createMailingListEntry } from "firebase/firebase.js";
import { db } from 'firebase.js'
import { getDocs, doc, setDoc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, push, child, update, set } from "firebase/database"

// Import Firebase analytics
import { getAnalytics, logEvent } from "firebase/analytics";

import { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";

const analytics = getAnalytics();
const Heading = tw.h1`font-bold text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Subheading = tw.h2`font-bold text-2xl md:text-2xl lg:text-2xl xl:text-3xl text-gray-900 leading-tight`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const BlogPosts = tw.div`mt-16 grid gap-8 lg:grid-cols-3 sm:grid-cols-2 grid-flow-row`;
const BlogPost = tw.div`bg-gray-200 rounded-lg`;
const Title = tw.h5`font-bold text-xl p-4`;
// const Date = tw.p`font-medium text-sm p-4`;
const Excerpt = tw.p`p-4`;
// Comment form full width
const CommentForm = tw.form `w-full rounded-lg border-2 border-gray-300 p-4 mt-8 mb-4`;
const NameInput = tw.input`w-full rounded-lg border-2 border-gray-300 p-4 mt-1 mb-1`;
const CommentInput = tw.textarea`w-full rounded-lg border-2 border-gray-300 p-4 mt-1 mb-1`;
const CommentButton = tw.button`w-full rounded-lg bg-primary-500 text-gray-100 p-4 mt-1 mb-1 hover:bg-primary-700 transition duration-300`;
const CommentBox = tw.div`mt-8 gap-8`;
const CommentText = tw.p`px-4 py-2`;
const CommentFooter = tw.p`font-medium text-sm px-4 py-1 text-right text-gray-500`;

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;






export default () => {

  // Set document title to "Octorati - Blog"
  useEffect(() => {
    document.title = "Octorati - About";
  }, []);

    // Set document description
  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute("content", "About Octorati.");
  }, []);

  

 
  return (

    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />
      <Content>

    {/* <AnimationRevealPage> */}
        {/* Heading */}
        
        <Subheading >
              About Octorati
            </Subheading>
        
        {/* Content */}
        <div>
        <p tw="mt-4">
        Octorati, born in 2023, emerged from a desire to harness the power of cut-price rates and trim those electricity bills. 
        </p>
        <p tw="mt-4">
        Octorati's creator didn't want another app, and found there wasn't a service to provide an email notification of upcoming prices. So, Octorati was created to provide users with a custom daily email of their specific upcoming rates, guiding them towards cost efficiency and financial victory!
        </p>
        <p tw="mt-4">
        Octorati is a free service, and we hope you find it useful. Rest assured we do not sell on your contact details, and we will only use them to contact you about Octorati. In order to cover the costs of running the service, we may include affiliate links or advertisements in our emails and on our site, but we'll keep this to a minimum.
        </p>
        <p tw="mt-4">
        If you have any feedback, we would love to hear from you - please drop us an email via the contact link.
        </p>
        </div>
        {/* Divider */}
        {/* <div tw="border-b-2 my-12 border-gray-300"></div> */}
        {/* Comments heading */}
        {/* </AnimationRevealPage>
        <AnimationRevealPage> */}
        
        <Subheading style={{marginTop: 30, marginBottom: 20,}}>
              About Octopus Energy and their Agile and Tracker tariffs
          </Subheading>
        {/* Content */}
        {/* About Octopus Energy the company */}
        <div>
        <p tw="mt-4">
        Octopus Energy is a UK-based energy supplier that was founded in 2015. Octopus Energy is known for its innovative approach to energy supply, and it has won several awards for its customer service and green energy initiatives.
        </p>
        {/* About the Octopus Energy Agile Tariff */}
        <p tw="mt-4">
        The Agile Octopus tariff was introduced by Octopus Energy in February 2018, just nine months after the launch of their Octopus Tracker tariff. Both tariffs are closely tied to current wholesale energy prices, but Agile Octopus takes this concept to a whole new level, with half-hour pricing.
        </p>
        <p tw="mt-4">
        Agile Octopus is a variable rate tariff, which means that the unit rate you pay for your electricity can change every half hour. This is because the price of electricity on the wholesale market changes every half hour, and Agile Octopus passes these changes on to you.
        </p>
        </div>
        {/* Comment Form */}
       
        {/* Comment input */}

        {/* Comments */}
       
      {/* </AnimationRevealPage> */}
      </Content>

      <Footer />

    </StyledDiv>
  );
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

// Import firebase helper file
// import { createMailingListEntry } from "firebase/firebase.js";
import { db } from 'firebase.js'
import { doc, setDoc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, push, child, update } from "firebase/database"

// Import Firebase analytics
import { getAnalytics, logEvent } from "firebase/analytics";

import Header from "../headers/headerLight.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
// import DesignIllustration from "../../images/design-illustration-2.svg";
import DesignIllustration from "../../images/windturbines.png";
// import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

const analytics = getAnalytics();






const Container = tw.div`relative`;
const OneColumn = tw.div`flex lg:w-8/12 text-center max-w-lg mx-auto lg:max-w-screen-xl lg:text-center py-10`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-2 md:py-2`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;





export default ({ roundedHeaderButton }) => {

  
// State for window width
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Declare state variables for email and tariff
  const [email, setEmail] = useState('');
  const [tariff, setTariff] = useState('Select your electricity tariff');
  const [tariffType, setTariffType] = useState('');
  const [tariffGroup, setTariffGroup] = useState('');
  const [postcode, setPostcode] = useState('');
  const [formEnabled, setFormEnabled] = useState(true);

  // Declare state for signup status
  const [signUpStatus, setSignUpStatus] = useState();

  
// Use Effect to determine window width
useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);


  // Handle submit and write to firestore
  const handleSubmit = (email, tariff, postcode, tariffType, tariffGroup) => {

    logEvent(analytics, '"Get started" button clicked');



    // If tariff selected and valid email address entered, based on length, write to firestore
    if (tariffType!='' && tariffGroup!='' && tariff !== 'Select your electricity tariff' && email.length > 4 && postcode.length >4 && postcode.length < 9  ) {
      console.log('tariff selected and email entered');

      // Set timeStamp based on Firebase time
      const createdAt = serverTimestamp();

      // Create lowercase const for email
      const emailLowerCase = email.toLowerCase();

      // Add a new document with a generated id.
      const docRef = addDoc(collection(db, "mailingList"), {
        email: emailLowerCase,
        tariff: tariff,
        postcode: postcode,
        createdAt: createdAt,
        gsp: "",
        tariffCode: "",
        tariffType: tariffType,
        tariffGroup: tariffGroup,
        sendMail: true,
        welcomeEmailSent: false,
      }).then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setSignUpStatus('Thank you for signing up! We will be in touch soon.');
        setFormEnabled(false);
      })
        .catch((error) => {
          console.error("Error adding document: ", error);
          setSignUpStatus('Sorry, something went wrong on our end. Please try again.');
        });

    } else {
      setSignUpStatus('Please enter valid email address, postcode and tariff details');
    }








  }



  return (
    <>
      {/* <Header roundedHeaderButton={roundedHeaderButton} /> */}
      <Container>
        <OneColumn>
        <Heading>
              Get a closer look at your <span tw="text-secondary-500">Octopus Agile</span> and <span tw="text-secondary-500">Octopus Tracker </span> electricity prices
            </Heading>
        </OneColumn>
        
        <TwoColumn>
          <LeftColumn>
           
            <Paragraph>
              Take advantage of <span tw="text-primary-500">import price drops or high export prices! </span> We make use of Octopus Energy’s official data APIs to give you <span tw="text-primary-500">easy to use information through personalised email notifications. Sign up with your tariff details here.</span>
            </Paragraph>
            {/* <Actions> */}

            {/* Dropdown, to choose whether tariff Group is Agile or Tracker */}
            <select name="tariffGroup" onChange={(e) => {
              setTariffGroup(e.target.value);
              console.log(tariffGroup);
            }}>
              <option value={tariffType} disabled selected hidden>Are you on an Agile or Tracker tariff?</option>
              <option value="Agile">Agile</option>
              <option value="Tracker">Tracker</option>
            </select>

            {/* Dropdown, to choose whether tariffType is Import (default) or Export */}
            <select name="tariffType" onChange={(e) => {
              setTariffType(e.target.value);
              console.log(tariffType);
            }}>
              <option value={tariffType} disabled selected hidden>Is it an import or export tariff?</option>
              <option value="Import">Import</option>
              <option value="Export">Export</option>
            </select>




            


            {/* Dropdown box to choose tariff */}

            {/* </Actions> */}

                        {/* Select option to save selected value as variable on change state using tariff and setTariff, available options based on tariffType */}
            <select onChange={(e) => {
              setTariff(e.target.value);
              console.log(tariff);
              console.log(email);
            }}>
              <option value={tariff} disabled selected hidden>What's your tariff name?</option>
              {
              (tariffGroup === 'Agile' && tariffType === 'Import') ? (
                <>
                  <option value="Agile Octopus April 2024 v1">Agile Octopus April 2024 v1</option>
                  <option value="Agile Octopus December 2023 v1">Agile Octopus December 2023 v1</option>
                  <option value="Agile Octopus November 2022 v1">Agile Octopus November 2022 v1</option>
                  <option value="Agile Octopus October 2022 v1">Agile Octopus October 2022 v1</option>
                  <option value="Agile Octopus August 2022 v1">Agile Octopus August 2022 v1</option>
                  <option value="Agile Octopus July 2022 v1">Agile Octopus July 2022 v1</option>
                </>
              ) :
              (tariffGroup === 'Agile' && tariffType === 'Export') ? (
                <>
                  <option value="Agile Outgoing Octopus February 2023 v1">Agile Outgoing Octopus February 2023 v1</option>
                  <option value="Agile Outgoing Octopus May 2019">Agile Outgoing Octopus May 2019</option>
                </>
              ) : 
              (tariffGroup === 'Tracker' && tariffType === 'Import') ? (
                <>
                  <option value="Tracker Octopus April 2024 v1">Agile Tracker April 2024 v1</option>
                  <option value="Tracker Octopus December 2023 v1">Agile Tracker December 2023 v1</option>
                  <option value="Tracker Octopus February 2023 v1">Agile Tracker February 2023 v1</option>
                  <option value="Tracker Octopus November 2022 v1">Agile Tracker November 2022 v1</option>
                  <option value="Tracker Octopus October 2022 v1">Agile Tracker October 2022 v1</option>
                </>
              ) : 
              (tariffGroup === 'Tracker' && tariffType === 'Export') ? (
                <>
                  <option disabled>No Tracker export rates available</option>
                </>
              ) : 
              (
                <option disabled>Select above details first</option> 
              )
              }
            </select>





            <input type="text" value={email} onChange={(e) => {
              setEmail(e.target.value);
              console.log(tariff);
              console.log(email);
            }}
              placeholder="Enter your email address" />

              

            <input type="text" value={postcode} onChange={(e) => {
              setPostcode(e.target.value);
            }}
              placeholder="Enter your postcode" />

              


            <button disabled={!formEnabled} onClick={() => handleSubmit(email, tariff, postcode, tariffType, tariffGroup)} >Get started</button>
            <p>{signUpStatus}</p>




            {/* Styled dropdown select box  */}
            <style jsx>{`
                select {
                  width: 90%;
                  padding: 10px 18px;
                  border: 1px solid #ccc;
                  border-radius: 12px;
                  box-sizing: border-box;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  font-size: 16px;
                  background-color: white;
                }
                input {
                  width: 90%;
                  padding: 10px 18px;
                  border: 1px solid #ccc;
                  border-radius: 12px;
                  box-sizing: border-box;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  font-size: 16px;
                  background-color: white;
                }
                button {
                  width: 90%;
                  padding: 10px 18px;
                  border: 1px solid #ccc;
                  border-radius: 12px;
                  box-sizing: border-box;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  font-size: 16px;
                  color: white;
                  background-color: #071952;
                }
              `}</style>




            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
          </LeftColumn>
            {/* Right column only to be shown if screen is xl */}
          

            
          {windowWidth > 1280 && 
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" style={{ paddingLeft: 20, paddingRight: 20 }} />
            </IllustrationContainer>
          </RightColumn>
          }
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};

// Page to display blog posts

import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/headers/headerLight.js";
import Footer from "../components/footers/FiveColumnWithBackground.js";

// Import firebase items
// Import firebase helper file
// import { createMailingListEntry } from "firebase/firebase.js";
import { db } from 'firebase.js'
import { getDocs, doc, setDoc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, push, child, update, set } from "firebase/database"

// Import Firebase analytics
import { getAnalytics, logEvent } from "firebase/analytics";

import { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";

const analytics = getAnalytics();
const Heading = tw.h1`font-bold text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const BlogPosts = tw.div`mt-16 grid gap-8 lg:grid-cols-3 sm:grid-cols-2 grid-flow-row`;
const BlogPost = tw.div`bg-gray-200 rounded-lg`;
const Title = tw.h5`font-bold text-xl p-4`;
// const Date = tw.p`font-medium text-sm p-4`;
const Excerpt = tw.p`p-4`;
// Comment form full width
const CommentForm = tw.form `w-full rounded-lg border-2 border-gray-300 p-4 mt-8 mb-4`;
const NameInput = tw.input`w-full rounded-lg border-2 border-gray-300 p-4 mt-1 mb-1`;
const CommentInput = tw.textarea`w-full rounded-lg border-2 border-gray-300 p-4 mt-1 mb-1`;
const CommentButton = tw.button`w-full rounded-lg bg-primary-500 text-gray-100 p-4 mt-1 mb-1 hover:bg-primary-700 transition duration-300`;
const CommentBox = tw.div`mt-8 gap-8`;
const CommentText = tw.p`px-4 py-2`;
const CommentFooter = tw.p`font-medium text-sm px-4 py-1 text-right text-gray-500`;

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;




// Blog data stored as HTML strings
const myBlogData = [
  {
    title: "Dynamic Pricing",
    content: "Agile Octopus is an innovative smart tariff that responds directly to wholesale market volatility. Customers gain access to half-hourly energy prices that mirror the fluctuations in wholesale prices. When wholesale electricity prices drop, your bills follow suit. If you can shift your electricity usage outside of peak times, you can save even more.",
  },
  {
    title: "Cheap, or even Free",
    content: "Agile Octopus offers the potential for cheap or even free electricity during periods of low and negative pricing. Negative pricing is when the wholesale price of electricity falls below zero. When this happens, Octopus Energy will pay you to use electricity. This is a rare occurrence, but it's a possibility with Agile Octopus, and great if you have some electric heaters to get going.",
  },
  {
    title: "Smart Meter",
    content: "Agile Octopus requires a smart meter, as it's the only way to access half-hourly pricing. If you don't already have a smart meter, Octopus Energy can install one for you. The installation is free, and you can book an appointment online. Once your smart meter is installed and tariff switch complete, you can start benefiting from Agile Octopus.",
  },
  {
    title: "Usage Ideas",
    content: "During off-peak hours when electricity prices are lower, you can strategically use certain appliances to save money. For example, you could run your washing machine, tumble dryer, and dishwasher at night. Consider using electric heaters during these hours, and adjust your fridge or freezer to a colder temperature. By doing so, you'll take advantage of reduced rates.",
  },
  {
    title: "Smart Plugs and Appliances",
    content: "You may not want to wake up at 3am to put on the washing machine. Consider using smart plugs to control your appliances remotely and on a timer. Smart plugs are a great way to schedule them to turn on and off at specific times. You can even ask Alexa to schedules this for you, based on the look-ahead of rates you receive from Octorati. With any luck, you could wake up to a warm house, with the dishes and laundry already washed and dried!",
  },
  {
    title: "Imports and Exports",
    content: "If you have a battery storage system, stockpile energy during low-price periods and unleash it as export when rates peak. Make sure you have Octopus Agile export tariff for this. As well as saving money, you'll be helping to balance the grid. If you have an electric vehicle, it can be possible to use the vehicle to store energy when rates are low, and export it back to the grid when rates are high.",
  },
  {
    title: "Supply and Demand",
    content: "Although we wait for specific daily rates, there are some typical trends on the electricity price. When the sun is shining and the wind is blowing, the prices are typically lower. This is because renewable energy sources are producing more electricity, and the demand is lower. The opposite is true when the weather is cold and dark. The demand is higher, supply is lower, and therefore  the prices are typically higher. In general terms, the price of electricity is high between 4pm and 8pm, and low overnight.",
  },
];



export default () => {

  // Set document title to "Octorati - Blog"
  useEffect(() => {
    document.title = "Octorati - Blog";
  }, []);

    // Set document description
  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute("content", "Blog posts from Octorati. Read about Agile Octopus, tips, tricks and more.");
  }, []);

  // Import "comments" documents from the firebase db ""blog_comments" collection
  const [comments, setComments] = useState([]);
  useEffect(() => {
    const getComments = async () => {
      const commentsCollection = collection(db, "blog_comments");
      const commentsSnapshot = await getDocs(commentsCollection);
      const commentsList = commentsSnapshot.docs.map(doc => doc.data());
      // Order the comments list
      commentsList.sort((a, b) => b.timestamp - a.timestamp);
      setComments(commentsList);
    }
    getComments();
  }, []);

  // State for name and comment
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [commentStatus, setCommentStatus] = useState('');
  const [commentFormEnabled, setCommentFormEnabled] = useState(true);

  //Convert firebase timestamp to date and time string
  const convertTimestamp = (timestamp) => {
    // Try the following, otherwise catch error
    try {
      // Create a new date from the timestamp
    const date = new Date(timestamp.toDate());
    return date.toLocaleString();
    } catch (error) {
      console.error("Error converting timestamp: ", error);
      return "unknown";
    }
  }

  //Function to handle form submission
 const handleSubmit = (event, name, comment) => {

  // Prevent default form submission
  event.preventDefault();

  logEvent(analytics, '"Submit comment" button clicked');

  // Check both fields are filled
  if (name !== '' && comment !== '' && name.length > 5 && comment.length > 10) {
    setCommentFormEnabled(false);
    // Add comment to firebase
    const addComment = async (name, comment) => {
      const commentsCollection = collection(db, "blog_comments");
      const newComment = {
        username: name,
        comment: comment,
        timestamp: serverTimestamp()
      };
      await addDoc(commentsCollection, newComment);
    }
    addComment(name, comment).then(() => {
      logEvent(analytics, 'comment_submitted', {
        name: name,
        comment: comment
      });
    
    setName('');
    setComment('');
    setCommentStatus('Comment submitted, it will appear shortly.');
    }).catch((error) => {
      console.error("Error adding document: ", error);

    });
  } else {
    console.log('Please fill in both fields');
    setCommentStatus('Please fill in both fields');
    setCommentFormEnabled(true);
  }

  }
  

 
  return (

    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />

    {/* <AnimationRevealPage> */}
      <Content>
        {/* Heading */}
        <Heading>
              Blog, Tips and Tricks for Octopus Agile
            </Heading>
        
        {/* Blog posts */}
        <BlogPosts>
          {myBlogData.map((post, index) => (
            <BlogPost key={index}>
              <Title>{post.title}</Title>
              <Excerpt>{post.content}</Excerpt>
            </BlogPost>
          ))}
        </BlogPosts>
        {/* Divider */}
        <div tw="border-b-2 my-12 border-gray-300"></div>
        {/* Comments heading */}
        <Heading style={{marginTop: 10, marginBottom: 20,}}>
              Comments
          </Heading>
        {/* Comment Form */}
        <CommentForm disabled={!commentFormEnabled}>
          <NameInput type="text" placeholder="Name" value={name} disabled={!commentFormEnabled} onChange={(e) => {
              setName(e.target.value);
            }}/>
          <CommentInput type="text" placeholder="Leave a comment" value={comment} disabled={!commentFormEnabled} onChange={(e) => {
            setComment(e.target.value);
          }}/>
          <CommentButton disabled={!commentFormEnabled} onClick={(event) => handleSubmit(event, name, comment)}>Submit Comment</CommentButton>
          <p>{commentStatus}</p>
        </CommentForm>
        {/* Comment input */}

        {/* Comments */}
        <CommentBox>
          {comments.map((comment, index) => (
            <BlogPost key={index} style={{marginTop: 20, marginBottom: 20}}>
              <CommentText>{comment.comment}</CommentText>
              <CommentFooter>By {comment.username}, {convertTimestamp(comment.timestamp)}</CommentFooter>
            </BlogPost>
          ))}
          </CommentBox>
      </Content>
      {/* </AnimationRevealPage> */}
      <Footer />

    </StyledDiv>
  );
};

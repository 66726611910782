import { getDatabase } from "firebase/database"
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";




const config = {
    apiKey: "AIzaSyALyZYOgtAsUSvtncYcWnUH6fFFkR_Svgw",
    authDomain: "octopus-agile-6f1c8.firebaseapp.com",
    databaseURL: "https://octopus-agile-6f1c8-default-rtdb.firebaseio.com",
    projectId: "octopus-agile-6f1c8",
    storageBucket: "octopus-agile-6f1c8.appspot.com",
    messagingSenderId: "296233626704",
    appId: "1:296233626704:web:98d7f9d6d4dc13a6d8f555",
    measurementId: "G-H6DV6R6X0H"
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };





// // //write document to firestore with request to sign up to mailing list
// // const createMailingListEntry = async (email, tarriff) => {
// //     const mailingListEntryRef = db.doc(`mailingList/${email}`);

// //     try {
// //         await mailingListEntryRef.set({
// //             email: email,
// //             tarriff: tarriff
// //         });
// //     } catch (error) {
// //         console.log('error creating mailing list entry', error.message);
// //     }
// // }

// export { initializeApp };

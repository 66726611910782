import React from "react"
import "style.css"
import "tailwindcss/lib/css/preflight.css"
import AnimationRevealPage from "helpers/AnimationRevealPage"
// import Hero from "./components/hero/FullWidthWithImage"

// Import react router
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Import pages
import Home from "pages/Home"
import Unsubscribe from "components/forms/Unsubscribe";
import Blog from "pages/Blog";
import About from "pages/About";




function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
            // <AnimationRevealPage>
            <Home />
          // </AnimationRevealPage>      
        } />
         <Route path="/unsubscribe" element={
            <Unsubscribe />
        } />
         <Route path="/blog" element={
            <Blog />
        } />
        <Route path="/about" element={
          <About />
        } />
      </Routes>
    </BrowserRouter>

  )
}

export default App
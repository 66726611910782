import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import Header from "../headers/headerLight.js";

// Import firebase items
// Import firebase helper file
// import { createMailingListEntry } from "firebase/firebase.js";
import { db } from 'firebase.js'
import { doc, setDoc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, push, child, update } from "firebase/database"

// Import Firebase analytics
import { getAnalytics, logEvent } from "firebase/analytics";

import { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";

const analytics = getAnalytics();





const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;


const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

export default () => {

    // Set document title to "Octorati - Unsubscribe"
    useEffect(() => {
      document.title = "Octorati - Unsubscribe";
    }, []);

  // Declare state for unsubscribe status
  const [unsubStatus, setUnsubtatus] = useState('');

  // Declare state for email address
  const [email, setEmail] = useState('');

  const [formEnabled, setFormEnabled] = useState(true);


  
// Handle submit and write to firestore
const handleSubmit = (event, email) => {

  // Prevent default form submission
  event.preventDefault();

  logEvent(analytics, '"Unsubscribe" button clicked');



  console.log(email);
  

  // If tarriff selected and valid email address entered, write to firestore
  if (email !== '') {
    setUnsubtatus('Requesting removal...');
    logEvent(analytics, 'Get started button clicked');


    
      // Set timeStamp based on Firebase time
      const updatedAt = serverTimestamp();

      // Set email to lowercase
      const emailLowerCase = email.toLowerCase();

      // Add a new document with a generated id.
      const docRef = addDoc(collection(db, "mailingUnsubList"), {      
        email: emailLowerCase,
        submittedAt: updatedAt,
        unsubscribed: false,
      }).then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setFormEnabled(false);
        setUnsubtatus('Unsubscribe request received, you will be removed shortly.');
      })
        .catch((error) => {
          console.error("Error adding document: ", error);
          setUnsubtatus('Sorry, something went wrong on our end. Please try again.');
        });




  } else {
    setUnsubtatus('Please enter an email address to unsubscribe.');
  }


}

  


  return (
    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />

    <AnimationRevealPage>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Unsubscribe</h2>
            <p>We'll be sorry to see you go. Please enter your email address below to unsubscribe.</p>

            <form >
              {/* <TwoColumn> */}
                {/* <Column> */}
                  {/* <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input id="name-input" type="text" name="name" placeholder="E.g. John Doe" />
                  </InputContainer> */}
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input 
                      id="email-input" 
                      type="email" 
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}

                       />
                  </InputContainer>
                  <p>{unsubStatus}</p>

                {/* </Column> */}
                {/* <Column> */}
                  {/* <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Message</Label>
                    <TextArea id="message-input" name="message" placeholder="E.g. Details about your event"/>
                  </InputContainer> */}
                {/* </Column> */}
              {/* </TwoColumn> */}

              {/* <SubmitButton type="submit"  value="Submit">Submit</SubmitButton> */}

              {/* "Submit Request button to handleunsubscribe" */}
              {/* <SubmitButton type="submit" onClick={handleSubmit} value="Submit">Submit</SubmitButton> */}
              <SubmitButton disabled={!formEnabled} onClick={(e) => handleSubmit(e, email)} >Submit</SubmitButton>



              {/* Styled dropdown select box  */}
            <style jsx>{`
                input {
                  width: 90%;
                  padding: 10px 18px;
                  border: 1px solid #ccc;
                  border-radius: 12px;
                  box-sizing: border-box;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  font-size: 16px;
                  background-color: white;
                }
              `}</style>

            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
      </AnimationRevealPage>
    </StyledDiv>
  );
};
